<template>
  <page-view>
    <div class="comm_wrapper">
      <a-button @click="handleCommInsert" type="primary">新增</a-button>

      <div class="comm_table">
        <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
          <span slot="serial" slot-scope="text, record, index">{{
            index + 1
          }}</span>

          <template slot="answer" slot-scope="text, scope">
            <div v-html="text"></div>
          </template>
          <template slot="operation" slot-scope="text, scope">
            <span @click="handleCommEdit(scope)" class="edit_btn">修改</span>
            |
            <a-popconfirm
              title="确认删除吗?"
              ok-text="确认"
              cancel-text="算了"
              @confirm="handleRemove(scope.id)"
            >
              <span class="edit_btn" style="color: red">
                删除
              </span>
            </a-popconfirm>
          </template>
        </s-table>
      </div>
      <a-modal
        title="新增"
        :width="560"
        :visible="showInsertModal"
        @cancel="handleCancel"
        @ok="handleOk"
      >
        <a-form>
          <a-form-item
            label="工单排序"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 24 },
              md: { span: 24 }
            }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 24 } }"
          >
            <a-input placeholder="工单排序" v-model.trim="insert.sort" />
          </a-form-item>

          <a-form-item
            label="工单问题"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 24 },
              md: { span: 24 }
            }"
            :wrapperCol="{ lg: { span: 18 }, sm: { span: 24 } }"
          >
            <a-input placeholder="工单问题" v-model.trim="insert.question" />
          </a-form-item>

          <a-form-item
            label="工单回答"
            :labelCol="{
              lg: { span: 3 },
              sm: { span: 24 },
              md: { span: 24 }
            }"
            :wrapperCol="{ lg: { span: 18 }, sm: { span: 24 } }"
          >
            <a-textarea
              style="height:130px;resize:none"
              placeholder="工单回答"
              :autoSize="{ minRows: 5 }"
              v-model.trim="insert.answer"
            />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </page-view>
</template>

<script>
import { PageView } from "@/layouts";

import { commList, commInsert, commUpdate, commRemove } from "@/self";
import { jsonToArray, noticeType, formatDate } from "@/utils/util";
import { STable } from "@/components";
export default {
  components: {
    PageView,
    STable
  },

  data() {
    return {
      showInsertModal: false,
      queryParam: {},
      insert: {},
      editTag: 0,

      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "serial" }
        },
        {
          title: "问题",
          dataIndex: "question"
        },
        {
          title: "回答",
          dataIndex: "answer",
          width: 480,
          scopedSlots: { customRender: "answer" }
        },
        {
          title: "发布时间",
          dataIndex: "createTime",
          customRender: formatDate,
          width: 200
        },
        {
          title: "创建时间",
          dataIndex: "updateTime",
          customRender: formatDate,
          width: 200
        },

        {
          title: "操作",
          dataIndex: "id",
          scopedSlots: { customRender: "operation" }
        }
      ],
      dataSource: parameter => {
        return commList(Object.assign(parameter)).then(result => {
          console.log(result.data);
          return result.data;
        });
      }
    };
  },

  methods: {
    handleCommInsert() {
      this.insert = {};
      this.showInsertModal = true;
      this.editTag = 0;
    },
    handleCommEdit(data) {
      this.editTag = 1;
      this.insert = JSON.parse(JSON.stringify(data));
      this.showInsertModal = true;
    },
    async handleRemove(id) {
      const res = await commRemove(id);
      if (res.success) {
        this.$refs.table.refresh(false);
      } else {
        this.$message.error(res.msg);
      }
    },
    handleCancel() {
      this.showInsertModal = false;
    },
    handleOk() {
      const { insert } = this;
      insert.sort = Number(insert.sort);
      if (!insert.question) {
        return this.$message.error("请输入问题内容");
      }
      if (!insert.answer) {
        return this.$message.error("请选择回答内容");
      }
      if (this.editTag == 0) {
        commInsert(insert).then(res => {
          if (res.success) {
            this.$message.success("新增成功");
            this.$refs.table.refresh(false);
            this.showInsertModal = false;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      if (this.editTag == 1) {
        commUpdate(insert).then(res => {
          if (res.success) {
            this.$message.success("修改成功");
            this.$refs.table.refresh(false);
            this.showInsertModal = false;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.comm_wrapper {
  background-color: #fff;
  padding: 24px;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;

  .edit_btn {
    cursor: pointer;
    color: #1890ff;
  }
  .comm_table {
    margin-top: 24px;
  }
}
</style>
