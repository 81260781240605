var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-view',[_c('div',{staticClass:"comm_wrapper"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleCommInsert}},[_vm._v("新增")]),_c('div',{staticClass:"comm_table"},[_c('s-table',{ref:"table",attrs:{"rowKey":"id","columns":_vm.columns,"data":_vm.dataSource},scopedSlots:_vm._u([{key:"serial",fn:function(text, record, index){return _c('span',{},[_vm._v(_vm._s(index + 1))])}},{key:"answer",fn:function(text, scope){return [_c('div',{domProps:{"innerHTML":_vm._s(text)}})]}},{key:"operation",fn:function(text, scope){return [_c('span',{staticClass:"edit_btn",on:{"click":function($event){return _vm.handleCommEdit(scope)}}},[_vm._v("修改")]),_vm._v(" | "),_c('a-popconfirm',{attrs:{"title":"确认删除吗?","ok-text":"确认","cancel-text":"算了"},on:{"confirm":function($event){return _vm.handleRemove(scope.id)}}},[_c('span',{staticClass:"edit_btn",staticStyle:{"color":"red"}},[_vm._v(" 删除 ")])])]}}])})],1),_c('a-modal',{attrs:{"title":"新增","width":560,"visible":_vm.showInsertModal},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('a-form',[_c('a-form-item',{attrs:{"label":"工单排序","labelCol":{
            lg: { span: 3 },
            sm: { span: 24 },
            md: { span: 24 }
          },"wrapperCol":{ lg: { span: 8 }, sm: { span: 24 } }}},[_c('a-input',{attrs:{"placeholder":"工单排序"},model:{value:(_vm.insert.sort),callback:function ($$v) {_vm.$set(_vm.insert, "sort", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"insert.sort"}})],1),_c('a-form-item',{attrs:{"label":"工单问题","labelCol":{
            lg: { span: 3 },
            sm: { span: 24 },
            md: { span: 24 }
          },"wrapperCol":{ lg: { span: 18 }, sm: { span: 24 } }}},[_c('a-input',{attrs:{"placeholder":"工单问题"},model:{value:(_vm.insert.question),callback:function ($$v) {_vm.$set(_vm.insert, "question", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"insert.question"}})],1),_c('a-form-item',{attrs:{"label":"工单回答","labelCol":{
            lg: { span: 3 },
            sm: { span: 24 },
            md: { span: 24 }
          },"wrapperCol":{ lg: { span: 18 }, sm: { span: 24 } }}},[_c('a-textarea',{staticStyle:{"height":"130px","resize":"none"},attrs:{"placeholder":"工单回答","autoSize":{ minRows: 5 }},model:{value:(_vm.insert.answer),callback:function ($$v) {_vm.$set(_vm.insert, "answer", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"insert.answer"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }